import ValueChart from "../charts/ValueChart";
import React from "react";
import { Row, Col, FormGroup, Label, UncontrolledTooltip } from "reactstrap";
import _ from "lodash";

const ValuesDisplayContainer = ({ useCase, flatValues = [], refresh }) => {
    /**Handles the display of the values in a container **/
    return (
        <div className="values-display-container flx-container">

            <FormGroup className="float-right pointer" id="icon-refresh" style={{ position: "absolute", zIndex: 3 }}>
                <Label onClick={refresh}>
                    <span className="material-symbols-outlined pointer">refresh</span>
                </Label>
                <UncontrolledTooltip target="icon-refresh">Refresh View</UncontrolledTooltip>
            </FormGroup>
            <Row className="flx-stretch-row">
                <ValueChart data={flatValues} useCase={useCase} />
            </Row>
        </div>
    );
};

export default ValuesDisplayContainer;
