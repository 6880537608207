import { AgGridReact } from "ag-grid-react";
import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import Context from "../../mainframe/Context";
import doFetch, { getServerUrl } from "../../../util/NetworkUtil";
import { UncontrolledTooltip } from "reactstrap";
import { useParams } from "react-router-dom";


const UseCaseList = ({ filter, editUseCases, deleteUseCases, showUseCaseDetails, copyUseCaseDetails }) => {
    const gridRef = useRef();
    let [context] = useContext(Context);

    let params = useParams()

    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        {
            field: "action", type: 'rightAligned',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,

            // headerComponent: () => (
            //     <>
            //         <span id="edit-all" className="material-symbols-outlined pointer" onClick={() => { }}>
            //             edit_note
            //         </span>
            //         <UncontrolledTooltip placement="top" target="edit-all">
            //             <b>Edit multiple UseCases</b>
            //         </UncontrolledTooltip>
            //     </>
            // ),
            field: "action",
            // filter: "agTextColumnFilter",
            resizable: true,
            flex: 0,
            width: 150,
            // cellClass: 'ag-right-aligned-cell',
            //suppressMenu: true,
            cellRenderer: cell => {
                return (
                    <>

                        <span id={"copy-" + cell.node.rowIndex}
                            className="material-symbols-outlined imprint pointer ms-16 "
                            style={{ padding: "4px 2px 4px 2px", }}
                            onClick={() => { prepareUseCasesForCopy(cell.node) }}
                        >
                            content_copy
                        </span>
                        <UncontrolledTooltip placement="top" target={"copy-" + cell.node.rowIndex}>
                            <b>Copy UseCase Attribute to clipboard</b>
                        </UncontrolledTooltip>

                        <span id={"editable-" + cell.node.rowIndex}
                            className="material-symbols-outlined imprint pointer ms-16 "
                            style={{ padding: "4px 2px 4px 2px" }}
                            onClick={() => { cell.node.setSelected(true); prepareUseCasesForEdit(gridRef.current.api.getSelectedNodes()) }}
                        >
                            edit
                        </span>
                        <UncontrolledTooltip placement="top" target={"editable-" + cell.node.rowIndex}>
                            <b>Edit UseCases</b>
                        </UncontrolledTooltip>


                        <span id={"deleteable-" + cell.node.rowIndex}
                            className="material-symbols-outlined imprint pointer ms-16 "
                            style={{ padding: "4px 2px 4px 2px" }}
                            onClick={() => { cell.node.setSelected(true); prepareUseCasesForDelete(gridRef.current.api.getSelectedNodes()) }}
                        >
                            delete
                        </span>
                        <UncontrolledTooltip placement="top" target={"deleteable-" + cell.node.rowIndex}>
                            <b>Delete UseCases</b>
                        </UncontrolledTooltip>

                        <span id={"detail-" + cell.node.rowIndex}
                            className="material-symbols-outlined imprint pointer ms-16 "
                            style={{ padding: "4px 2px 4px 2px" }}
                            onClick={() => { gridRef.current.api.deselectAll(); cell.node.setSelected(true); prepareUseCasesForDetails(gridRef.current.api.getSelectedNodes()) }}
                        >
                            bid_landscape
                        </span>
                        <UncontrolledTooltip placement="top" target={"detail-" + cell.node.rowIndex}>
                            <b>Show Detail</b>
                        </UncontrolledTooltip>
                    </>
                )
            }
        },

        {
            field: "dataType",

        },
        { field: "restriction" },
        { field: "commodity" },
        { field: "product" },
        { field: "unit" },
        { field: "observation" },
        { field: "period" },
        { field: "type" },
        { field: "subtype" },
        { field: "startDate" },
        { field: "area" },
        { field: "source" },
        { field: "responsiblePerson" },
        { headerName: "First Data", field: "firstObsTimestamp", valueGetter: p => new Date(p.data.firstObsTimestamp), type: "dateTime" },
        { headerName: "Last Data", field: "lastObsTimestamp", valueGetter: p => new Date(p.data.lastObsTimestamp), type: "dateTime" },
        { headerName: "Last Mod.", field: "lastModification", valueGetter: p => new Date(p.data.lastModification), type: "dateTime" },
    ]);

    const defaultColumDef = { flex: 1, resizable: true, suppressMenu: false, sortable: true };

    useEffect(() => {

        let queryString = Object.keys(filter).map(k => `${encodeURI(k)}=${encodeURI(filter[k])}`).join("&")


        doFetch({
            url: `${getServerUrl()}/usecases?` + queryString,
            // loadingFn: setIsLoading,
            dataFn: response => {
                if (response) setRowData(response);

            },
            errorText: "Not found, or get failed."
        });
    }, [filter])

    const columnTypes = useMemo(() => {
        return {
            dateTime: {
                minWidth: 130,
                type: 'rightAligned',
                cellClass: 'ag-right-aligned-cell',
                headerClass: 'ag-right-aligned-header',
                // valueFormatter: p => p.value ? p.value.toLocalDateString("de-CH") : ""
                valueFormatter: p => p.value.toLocaleString("de-CH", {
                    minute: 'numeric',
                    hour: 'numeric',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                })
            }
        };
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent' },
                { statusPanel: 'agTotalRowCountComponent' },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' }
            ]
        };
    }, []);

    /**
     * Convert the aggrid nodes to editable UseCases
     * @param {*} selectedNodes 
     */
    const prepareUseCasesForEdit = selectedNodes => {
        editUseCases(selectedNodes.map(node => node.data))
    }

    /**
     * Convert the aggrid nodes to deletabel UseCases
     * @param {*} selectedNodes 
     */
    const prepareUseCasesForDelete = selectedNodes => {
        deleteUseCases(selectedNodes.map(node => node.data))
    }

    /**
     * Convert the aggrid node to UseCases
     * @param {*} selectedNodes 
     */
    const prepareUseCasesForDetails = selectedNodes => {
        let [useCase] = selectedNodes.map(node => node.data)
        if (useCase) showUseCaseDetails(useCase)
    }

    /**
     * Convert the aggrid node to UseCases
     * @param {*} selectedNodes 
     */
    const prepareUseCasesForCopy = selectedNode => {
        let useCase = selectedNode.data
        copyUseCaseDetails(useCase)
    }

    console.log("i render")

    return (<>
        <div
            className={context.theme === "dark" ? "ag-theme-balham-dark theme-ompex" : "ag-theme-balham theme-ompex"}
            style={{ height: "100%", width: "100%", }}
        >
            <AgGridReact ref={gridRef}
                columnTypes={columnTypes}

                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColumDef}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                rowMultiSelectWithClick={true}

                statusBar={statusBar}
            />
        </div>
    </>

    );
};

export default UseCaseList;
