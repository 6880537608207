import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const AreYouSureModal = (props) => {
    const { onConfirm, onCancel, isOpen } = props;

    return (
        <Modal isOpen={isOpen} toggle={onCancel}>
            <ModalHeader>Are you sure about that?</ModalHeader>
            <ModalBody>
                <p>
                    This will delete the ressource <strong>FOREVER!!</strong>
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="" className="danger" onClick={onConfirm}>
                    Confirm
                </Button>
                <Button color="secondary" onClick={onCancel}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AreYouSureModal;
