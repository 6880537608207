import React from "react";

// Ohne Destructuring.
const Footer = (props) => (
    <div className="flx-fixed-row footer container-gravity">
        <span className="element-gravity">{props.message}</span>
    </div>
);

export default Footer;
