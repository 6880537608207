import _ from "lodash";

/**
 * Determined the server URL
 */
const getServerUrl = () => {
    let url = window.location;
    if (url.hostname === "localhost") {
        return url.protocol + "//data.api." + url.hostname.replace("data.","") + ":8081/api/v1";
    } else {
        return url.protocol + "//data.api." + url.hostname.replace("data.","") + "/api/v1";
    }
};

/**
 * Determined the server URL
 */
const getLogServerUrl = () => {
    return `http://omplogger.ompex.local:8080`;
};

/**
 * Determined the server URL
 */
const getSocketUrl = () => {
    let url = window.location;
    if (url.hostname === "localhost") {
        return url.protocol + "//data.api." + url.hostname.replace("data.","") + ":8081/api/socket";
    } else {
        return url.protocol + "//data.api." + url.hostname.replace("data.","") + "/api/socket";
    }
};

/**
 * Ein Funktion, welche den Fetch-Request absetzt.
 *
 * @param {string} url Der URL
 * @param {object} requestObject Das Objekt mit den Headern, Methoden (wenn nicht GET) (Optional) {method: 'PUT', body: JSON.stringify(payload), ...headers}
 * @param {function} dataFn Die Funktion, welche die Daten verarbeitet
 * @param {function} loadingFn Die Funktion, die den Loadingzustand setzt
 * @param {string} errorText Den Fehlertext
 */
export const doFetch = async ({ url, requestObject, dataFn, loadingFn, errorText, errorFn }) => {
    try {
        (loadingFn || _.identity)(true);
        const response = await fetch(url, requestObject);
        if (response.ok) {
            // Mit leerem Response umgehen:
            const json = response.status !== 204 ? await response.json() : null;
            dataFn(json);
        } else {
            if (errorFn) errorFn(response)
            else
                throw new Error(`${errorText}: ${response.status}`);
        }
    } catch (error) {
        throw error;
    }
    (loadingFn || _.identity)(false);
};

/**
 * Certain chars are problematic if the string is transmitted via URLparam. .eg & For that matter they have to be
 * replaced with the corresponding hex codes
 * @param {String} str
 */
const mkURLrdy = unworthyString => {
    var specialChar = [
        ["&", "%26"],
        ["+", "%2B"]
    ];
    for (var i = 0; i < specialChar.length; i++) {
        unworthyString = unworthyString.replace(specialChar[i][0], specialChar[i][1]);
    }
    var worthyString = unworthyString;
    return worthyString;
};

export default doFetch;
export { mkURLrdy, getServerUrl, getLogServerUrl, getSocketUrl };
