import { Navbar, NavbarBrand, NavbarText } from "reactstrap";
import React from "react";
import Logo from "../../assets/images/Logo";

const Header = ({ midContent, rightContent }) => {
    return (
        <div className="flx-normal-row header">
            <Navbar>
                <NavbarBrand href="/">
                    <Logo className="header-logo" />
                </NavbarBrand>
                <NavbarText>{rightContent}</NavbarText>
                {midContent}
            </Navbar>
        </div>
    );
};

export default Header;
