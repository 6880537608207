import React from "react";
import { Badge } from "reactstrap";

const UseCaseUsage = props => {
    let { title, amount } = props;
    return (
        <>
            <p>
                <span>{title} </span>
                <Badge color="secondary" pill>
                    {amount}
                </Badge>
            </p>
        </>
    );
};

export default UseCaseUsage;
