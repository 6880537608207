import React from "react";
import { Card, CardBody, CardTitle, CardFooter, Spinner } from "reactstrap";

const Metric = props => {
    let { title, value } = props;
    return (
        <Card>
            <CardBody>
                <CardTitle tag="h2" className="text-right">
                    {value === undefined ? <Spinner></Spinner> : value}
                </CardTitle>
            </CardBody>
            <CardFooter className="text-muted text-right">{title}</CardFooter>
        </Card>
    );
};

export default Metric;
