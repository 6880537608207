import React from "react";

import { useParams } from "react-router-dom";
import UseCaseValuesContainer from "./UseCaseValuesContainer";
import _ from "lodash";
import { Col, Row } from "reactstrap";
import SymbolMessage from "../cosmetic/SymbolMessage";
import UseCase from "../elements/UseCase";

const DetailContainer = ({ useCase = {}, selectionOptions, copyUseCaseDetails = () => { } }) => {
    /**Displays the Details of a container  **/



    if (useCase) {
        return (
            <div className="detail-container flx-container">
                <Row className="flx-stretch-row" style={{ height: "300px" }}>
                    <UseCaseValuesContainer useCase={useCase} />
                </Row>
                <Row className="flx-stretch-row">
                    <UseCase useCase={useCase}
                        selectionOptions={selectionOptions}
                        copyUseCaseDetails={copyUseCaseDetails} />
                </Row>
            </div>
        );

    }
    else {
        return <SymbolMessage title="Nope" message="Page not found" icon="explore_off" />;
    }
};

export default DetailContainer;
