import React from "react";

import EntryPage from "../cosmetic/EntryPage";

import { Routes, Route } from "react-router-dom";
import DetailContainer from "../container/DetailContainer";
import ApiDocContainer from "../container/ApiDocContainer";
import UseCaseContainer from "../container/UseCaseContainer";

const DetailRoute = () => {
    /**Displays the Details of a container  **/
    let title = "UseCase Manager";
    let subtitle = "Ompex Data";
    if (document.body.classList.contains("testing")) {
        title = <b>Test System</b>;
        subtitle = 'this is not live';
    }
    if (document.body.classList.contains("redundant")) {
        title = <b>Redundanz System</b>;
        subtitle = 'fallback system';
    }
    return (
        <div className="detail-route">
            <Routes>
                <Route path="/usecases" element={<UseCaseContainer title={"title"} subtitle={"subtitle"} />}></Route>
                <Route path="/api-doc" element={<ApiDocContainer file="/api-public.yml" />}></Route>
                <Route path="/api-doc-dev" element={<ApiDocContainer file="/api-developer.yml" />}></Route>
                <Route path="/*" element={<EntryPage title={title} subtitle={subtitle} />}></Route>
            </Routes>
        </div>
    );
};

export default DetailRoute;
