const sortArrayByDate = arr => {
    arr.sort((a, b) => parse(b.date, b.time) - parse(a.date, a.time));
    return arr;
};

const sortArrayByAttrName = arr => {
    arr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    return arr;
};

//helper function to sort relationelements
const parse = (date, time) => {
    if (date && time) {
        var d = date.substr(0, 2),
            m = date.substr(3, 5),
            y = date.substr(6, 10),
            h = time.substr(0, 2),
            mm = time.substr(3, 5);
        return new Date(y, m, d, h, mm);
    }
    return new Date(1800, 12, 24, 4, 20);
};
/**
 *
 * @param {*} date in this format dd.mm.yyyy
 */
const formatDate = date => {
    var parts = date.split(".");
    var d = new Date(parts[2], parts[1], parts[0]),
        month = "" + d.getMonth(),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
};

/**
 * Formats a stringdate to this format dd.mm.yyyy hh:mm
 * @param {*} date in iso format
 */
const formatDateTime = datetime => {
    if(!datetime) return "-"
    var d = new Date(datetime);
    return (
        ("0" + d.getDate()).slice(-2) +
        "." +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "." +
        d.getFullYear() +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2)
    );
};

export default parse;
export { sortArrayByDate, sortArrayByAttrName, formatDate, formatDateTime };
