const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Copies a Text to the clipboard
 * @param {*} textToCopy 
 */
async function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}

/**
 * Formats a number to 2k instead of 2000
 * @param {*} num
 * @param {*} digits
 * @returns
 */
const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

/**
 * Interpolates a color between two values
 * @param {*} c0
 * @param {*} c1
 * @param {*} f
 * @returns
 */
export const interpolateColor = (c0, c1, f) => {
    c0 = c0.match(/.{1,2}/g).map(oct => parseInt(oct, 16) * (1 - f));
    c1 = c1.match(/.{1,2}/g).map(oct => parseInt(oct, 16) * f);
    let ci = [0, 1, 2].map(i => Math.min(Math.round(c0[i] + c1[i]), 255));
    return ci
        .reduce((a, v) => (a << 8) + v, 0)
        .toString(16)
        .padStart(6, "0");
};

export { capitalizeFirstLetter, nFormatter, copyToClipboard };
