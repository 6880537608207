import React from "react";

function Logo({ className }) {
    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 379.533 84.92" xmlSpace="preserve">
                <g transform="matrix(1.25246 0 0 -1.25246 14.325 81.729)">
                    <g transform="scale(.1)">
                        <path d="M1560.79 524.246c-70.6 0-128.05-57.441-128.05-128.047 0-70.605 57.45-128.047 128.05-128.047 70.61 0 128.05 57.442 128.05 128.047 0 70.606-57.44 128.047-128.05 128.047zm239.99-128.047c0-134.574-102.34-239.988-232.99-239.988-46.44 0-90.11 17.977-126.26 51.219V0h-113.75v625.273h111.54v-49.761c40.11 39.293 87.99 60.679 137.26 60.679 123.63 0 224.2-107.66 224.2-239.992zm309.57 240.703c-132.08 0-239.54-107.863-239.54-240.453 0-132.074 107.32-239.523 239.55-239.523 79.89.047 154.47 39.859 199.49 106.488l8.33 12.328-124.39 26.141-3.69-2.977c-18.06-14.574-46.57-31.949-79.82-31.957-69.46.024-130.39 60.535-130.39 129.5 0 68.629 59.75 126.606 130.46 126.606 51.51 0 97.85-31.625 117.05-78.364h-162.83v-99.793h280.71l1.52 8.176c2.68 14.539 4.03 29.137 3.99 43.395-.23 132.574-108.09 240.433-240.44 240.433zm562.75-249.031l173.44 237.402h-141.79L2604.07 484.84l-100.68 140.433h-140.38l171.18-237.398-165.04-228.168h139.8l95.21 131.152 95.05-131.152h142.13zM239.285 522.656c-69.722 0-126.453-56.73-126.453-126.457 0-69.211 56.731-125.519 126.453-125.519 69.723 0 126.445 56.308 126.445 125.519 0 69.727-56.722 126.457-126.445 126.457zm240.203-126.457c0-131.937-107.754-239.273-240.203-239.273C107.344 156.926 0 264.262 0 396.199c0 132.453 107.344 240.207 239.285 240.207 132.449 0 240.203-107.754 240.203-240.207zm744.262 44.559V159.707h-113.76v281.051c0 43.84-34.34 78.183-78.17 78.183-43.84 0-78.183-34.343-78.183-78.183V159.707H838.949v281.051c0 43.84-34.34 78.183-78.183 78.183-43.836 0-78.176-34.343-78.176-78.183V159.707H568.828v281.051c0 106.34 86.106 192.859 191.938 192.859 50.75 0 99.547-20.535 135.519-56.695 35.988 36.16 84.793 56.695 135.535 56.695 105.83 0 191.93-86.519 191.93-192.859"></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Logo;
