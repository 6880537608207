import React from "react";
import { Col } from "reactstrap";

const UseCaseAttribute = ({ label, attribute, labelWidth = 2, attrWidth = 9, labelClass }) => {
    /**
     * display id, unit, commodity, product, period, source, area, observation, type, lastModification of useCase
     * */
    return (
        <>
            <Col xs={labelWidth}>
                <span className={labelClass}>{label}: </span>
            </Col>
            <Col xs={attrWidth}>
                <span className="attribute">{attribute ? attribute : "-"}</span>
            </Col>
        </>
    );
};

export default UseCaseAttribute;
