import React from "react";
import generateHighlightedSyntax from "../../util/syntax-highlight/SyntaxHighlighter";

const CodeBlock = ({ code, language }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(code);
    };

    return (
        <div className="omp-code-block">
            <span class="material-symbols-outlined code-copy-icon pointer" onClick={copyToClipboard}>
                content_copy
            </span>
            <div className="highlighted">{generateHighlightedSyntax(code, language)}</div>
        </div>
    );
};

export default CodeBlock;
