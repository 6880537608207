import _ from "lodash";
import React, { useContext, useRef, useState, useLayoutEffect } from "react";

import { Button, ButtonGroup, Col, Row, } from "reactstrap";
import Context from "../../mainframe/Context";
import { capitalizeFirstLetter } from "../../../util/Util";
import Select from "react-dropdown-select";

const ButtonFilter = ({ attrName, options, filter, updateFilter, showSearchField = true, isLoading }) => {
    /**Handles the display of the data in a container **/

    let [context] = useContext(Context);
    const targetRef = useRef();

    const [visibleItems, setVisibleItems] = useState([]);

    if (filter[attrName]) console.log(filter[attrName])
    const classes = context.theme === "dark" ? "dark" : ""

    useLayoutEffect(() => {

        const containerWidth = 1400
        let currentWidth = 0;
        let allfit = true;
        const charlength = 4;

        for (let item of options) {
            const buttonWidth = item.length * charlength + 20; // 20 for padding and margin
            if (currentWidth + buttonWidth > containerWidth) {
                allfit = false;
                break;
            }
            currentWidth += buttonWidth;
        }

        if (allfit) {
            setVisibleItems(options.filter(f => f !== undefined && f !== null).map(option => <Button
                outline

                key={attrName + "_" + option}
                option={option}
                hidden={showSearchField && filter[attrName] === option}
                active={filter[attrName] === option}
                color="usecase-filter"
                size="sm"
                onClick={() => {
                    updateFilter(attrName, option);
                }}
                style={{ marginTop: "1px" }}
            >
                {option === "" ? '""' : option}
            </Button >))

        } else {
            setVisibleItems([<span
                key={attrName + "_" + "toomany"}
                style={{
                    fontSize: "80%",
                    color: "grey",
                    marginTop: "auto",
                    marginBottom: "auto"

                }}

            > {options.length} weitere Optionen ...</span>])
        }

    }, [options]);





    return (
        <>
            <Row key={"r_" + attrName} className={"selector-row filter-row-" + attrName}>
                {/* <Col key={"c3_" + key} xs="2"></Col> */}
                <Col key={"c1_" + attrName} className="align-middle" xs="1" style={{ minWidth: "160px" }} >
                    <span>{capitalizeFirstLetter(attrName)}</span>
                </Col>
                {showSearchField ?
                    <Col key={"c1_" + attrName + "_filter"} className="align-middle" xs="1" style={{}}>
                        <Select
                            values={filter[attrName] ? [{ name: filter[attrName] }] : []}
                            options={options.map(o => { return { name: o } })}
                            labelField={"name"}
                            valueField={"name"}
                            create
                            createNewLabel={"nach {search} suchen"}
                            onCreateNew={(newEntry) => {
                                newEntry.name_like = newEntry.name
                                console.log("new")
                            }}
                            clearable
                            loading={isLoading}
                            className={filter[attrName] || filter[attrName + "_like"] ? classes + " option-selected" : classes}
                            placeholder={`${attrName} suchen`}
                            dropdownGap={0}
                            searchable
                            onClearAll={() => { updateFilter(attrName, null); }}
                            dropdownHandle={false}
                            onChange={(values) => {
                                if ((filter[attrName] && filter[attrName] === values[0]?.name) || //filter is already set - prevent loop
                                    (filter[attrName + "_like"] && filter[attrName + "_like"] === values[0]?.name_like)) // _like filter is already set - prevent loop
                                    return


                                console.log("change", values)
                                //prevent loop
                                if (values[0]?.name_like) {
                                    updateFilter(attrName + "_like", values[0].name_like)

                                } else if (values[0]?.name) {
                                    updateFilter(attrName, values[0].name)
                                }
                            }}
                        ></Select>
                    </Col>
                    : <></>}
                <Col key={"c5_" + attrName} xs="9" style={showSearchField ? { paddingLeft: "30px", display: "flex", } : { display: "flex", }} >
                    <ButtonGroup
                        key={"btg_" + attrName}
                    >
                        {visibleItems}
                    </ButtonGroup>
                </Col>
            </Row >

        </>
    );
};

export default ButtonFilter;
