import React, { useEffect, useState } from "react";
import { Col, UncontrolledTooltip, Row, } from "reactstrap";
import { useNavigate } from "react-router-dom";
import doFetch, { getLogServerUrl } from "../../util/NetworkUtil";
import { getServerUrl } from "../../util/NetworkUtil";
import _ from "lodash";
import UseCaseModal from "../modals/UseCaseModal";
import { formatDateTime } from "../../util/ReactUtil";
import UseCaseAttribute from "./UseCaseAttribute";
import UseCaseUsage from "./UseCaseUsage";
import UseCaseUsageModal from "../modals/UseCaseUsageModal";
import FinancialIcon from "../icons/FinancialIcon";
import CodeModal from "../modals/CodeModal";


const attributes = [
    { name: "commodity", hint: "commodity" },
    { name: "area", hint: "area" },
    { name: "product", hint: "product" },
    { name: "source", hint: "source" },
    { name: "type", hint: "type" },
    { name: "subtype", hint: "subtype" },
    { name: "unit", hint: "unit" },
    { name: "period", hint: "period" },
    { name: "observation", hint: "observation" },
    { name: "startDate", hint: "startDate" },
    { name: "responsiblePerson", hint: "responsiblePerson" },
]

const UseCase = ({ useCase = {}, selectionOptions = {}, copyUseCaseDetails = () => { } }) => {
    // let [context, setContext] = useContext(Context);
    let [ompSettings, setOmpSettings] = useState();
    let [logUsages, setLogUsages] = useState();
    let [editOpen, setEditOpen] = useState(false);
    let [usageOpen, setUsageOpen] = useState(false);

    let [codeOpen, setCodeOpen] = useState(false);


    let navigate = useNavigate();

    const removeUseCase = id => {
        navigate("/usecases");
    };

    const editUseCase = e => {
        e.stopPropagation();
        setEditOpen(true);
    };

    const toggleUsages = e => {
        setUsageOpen(!usageOpen);
    };

    const showCode = useCase => {
        setCodeOpen(true);
    };

    useEffect(() => {
        console.log(useCase);
        if (useCase.uc === "ncd") {
            var name = atob(useCase.uId)
                .split("_")
                .slice(0, 9);
            doFetch({
                url: `${getLogServerUrl()}/cases/ompModules/entries?ompModule=ompDB&param1=${encodeURIComponent(name.join("_"))}`,
                loadingFn: () => { },
                dataFn: response => {
                    // setLoadingUsages(false);
                    setLogUsages(response);
                    //adjust the id incase this was a csvusecase
                },
                errorText: "Not found, or put failed."
            });
        }
        doFetch({
            url: `${getServerUrl()}/ompsettings`,
            loadingFn: status => { },
            dataFn: response => {
                setOmpSettings(response);
                console.log(response);
            },
            errorText: "Not found, or get failed."
        });
    }, []);

    /**
     * Deletes the UseCase
     * @param {*} useCase
     */
    const doDeleteUseCase = useCases => {
        deleteUseCases(useCases[0], response => {
            removeUseCase(useCase[0].id);
        });
    };

    /**
     * Updates the UseCase
     */
    const doUpdateUseCase = useCases => {
        updateUseCases(useCases[0], response => {
            //adjust the id incase this was a csvusecase
            navigate(`/usecases/${response.uId}`);
            console.log(response);
            setEditOpen(false);
        });
    };

    var aliases = [];
    if (ompSettings && ompSettings.databaseAttributes) {
        aliases = ompSettings.databaseAttributes.filter(attr => {
            return (
                (!attr.settings.area || useCase.area === attr.settings.area) &&
                (!attr.settings.commodity || useCase.commodity === attr.settings.commodity) &&
                (!attr.settings.period || useCase.period === attr.settings.period) &&
                (!attr.settings.product || useCase.product === attr.settings.product) &&
                (!attr.settings.source || useCase.source === attr.settings.source) &&
                (!attr.settings.type || useCase.type === attr.settings.type) &&
                (!attr.settings.unit || useCase.unit === attr.settings.unit)
            );
        });
    }



    const mailSubject = encodeURIComponent("Feature coming soon");
    const mailBody = encodeURIComponent("cool");

    let logStatistic = {};
    if (logUsages) {
        logUsages.entries.forEach(entry => {
            if (logStatistic[entry.application]) logStatistic[entry.application]++;
            else logStatistic[entry.application] = 1;
        });
    }

    /**
     * display id, unit, commodity, product, period, source, area, observation, type, lastModification of useCase
     * */
    return (
        <Col className="usecase flx-stretch-row" style={{ fontSize: "10px" }}>
            <Row className="">
                <Col>
                    {useCase.lastModification ? <span className="last-modification"> last mod. {formatDateTime(useCase.lastModification)}</span> : <div />}
                </Col>
                <Col>

                    <span id="icon-edit" className="material-symbols-outlined ms-18 float-right  pointer" onClick={editUseCase}>
                        edit
                    </span>
                    <UncontrolledTooltip target="icon-edit"> UseCase Bearbeiten</UncontrolledTooltip>
                    <span className="vertical-separation right-border float-right" style={{ margin: "0px 20px ", height: "24px" }}> </span>

                    <span id="icon-code" className="material-symbols-outlined ms-18 float-right pointer " onClick={showCode}>
                        code_blocks
                    </span>
                    <UncontrolledTooltip target="icon-code"> Code Beispiele</UncontrolledTooltip>
                    <span className="vertical-separation right-border float-right" style={{ margin: "0px 20px ", height: "24px" }}> </span>

                    <span id="icon-copy" className="material-symbols-outlined ms-18 float-right  pointer" onClick={() => copyUseCaseDetails(useCase)}>
                        content_copy
                    </span>
                    <UncontrolledTooltip target="icon-copy"> UseCase Attribute kopieren</UncontrolledTooltip>

                </Col>
            </Row >

            <Row className="separate"></Row>


            <Row className="usecase-attributes">
                {/* <UseCaseAttribute label="Id" attribute={useCase.id} /> */}
                <UseCaseAttribute label="Commodity" attribute={useCase.commodity} labelClass="label" />
                <UseCaseAttribute label="Area" attribute={useCase.area} labelClass="label" />
                <UseCaseAttribute label="Product" attribute={useCase.product} labelClass="label" />
                <UseCaseAttribute label="Source" attribute={useCase.source} labelClass="label" />
                <UseCaseAttribute label="Type" attribute={useCase.type} labelClass="label" />
                <UseCaseAttribute label="Subtype" attribute={useCase.subtype} labelClass="label" />
                <UseCaseAttribute label="Unit" attribute={useCase.unit} labelClass="label" />
                <UseCaseAttribute label="Period" attribute={useCase.period} labelClass="label" />
                {useCase.dataType === "oncd" ? <UseCaseAttribute label="Observation" attribute={useCase.observation} labelClass="label" />
                    : <UseCaseAttribute label="StartDate" attribute={useCase.startDate} labelClass="label" />}

            </Row>
            <Row className="separate"></Row>

            <Row className="usecase-attributes">

                <UseCaseAttribute
                    label="Responsible"
                    attribute={useCase.responsiblePerson ?
                        <a href={"mailto:digi@ompex.ch?subject=" + mailSubject + "&body=" + mailBody}>{useCase.responsiblePerson}</a> :
                        <a className="pointer" onClick={editUseCase}>add</a>}
                    labelClass="label-sec"
                />
                <UseCaseAttribute label="FirstObsTimestamp" attribute={formatDateTime(useCase.firstObsTimestamp)} labelClass="label-sec" />
                <UseCaseAttribute label="LastObsTimestamp" attribute={formatDateTime(useCase.lastObsTimestamp)} labelClass="label-sec" />
                <UseCaseAttribute label="Classification" attribute={useCase.dataType} labelClass="label-sec" />
                <UseCaseAttribute label="Restriction" attribute={useCase.restriction === "financial" ? <FinancialIcon /> : "-"} labelClass="label-sec" />
            </Row>

            <Row className="separate"></Row>
            <Row>
                <span>
                    <strong>Alias Groups:</strong>
                </span>
                {aliases.length > 0 ? <></> : <span className="no-alias"> no alias found</span>}
                <ul>
                    {_.map(aliases, (alias, i) => (
                        <li key={alias.name}>
                            <span id={alias.name}>{alias.name}</span>
                            <UncontrolledTooltip key={alias.name + i} placement="top" target={alias.name}>
                                <strong>Team: {alias.team ? alias.team : "-"}</strong>

                                {_.map(Object.keys(alias.settings), (key, i) => (
                                    <div key={key + i}>
                                        <span>
                                            {key}: {alias.settings[key]}
                                        </span>
                                        <br />
                                    </div>
                                ))}
                            </UncontrolledTooltip>
                        </li>
                    ))}
                </ul>
            </Row>
            <Row className="separate"></Row>

            <Row>
                <span>
                    <strong>Application:</strong>{" "}
                    <span className="material-symbols-outlined ms-18 pointer" onClick={toggleUsages}>
                        troubleshoot
                    </span>
                </span>

                <Col>
                    {_.map(Object.keys(logStatistic), key => (
                        <UseCaseUsage title={key} amount={logStatistic[key]}></UseCaseUsage>
                    ))}
                </Col>
                <Col>{/* <UseCaseUsesTable uses={logUsages ? logUsages.entries : []} isLoading={!logUsages} /> */}</Col>
            </Row>
            <div></div>

            <div></div>
            {
                editOpen ? (
                    <UseCaseModal
                        useCases={[useCase]}
                        onClose={() => setEditOpen(false)}
                        onSubmit={doUpdateUseCase}
                        onDelete={doDeleteUseCase}
                        selectionOptions={selectionOptions}
                    />
                ) : (
                    <div />
                )
            }
            {codeOpen ? <CodeModal useCase={useCase} onClose={() => setCodeOpen(false)} /> : <div />}

            <UseCaseUsageModal usages={logUsages ? logUsages.entries : []} isOpen={usageOpen} toggle={toggleUsages} onClose={() => setUsageOpen(false)} />
        </Col >
    );
};
const createUseCase = (useCase, onComplete) => {
    let serverUrl = getServerUrl();
    doFetch({
        url: `${serverUrl}/usecases`,
        requestObject: {
            method: "POST",
            body: JSON.stringify(useCase)
        },
        dataFn: onComplete,
        loadingFn: status => { }
    });
};


/**
 * Updates an array Of Useacses
 * @param {*} useCases 
 * @param {*} onComplete 
 */
const updateUseCases = async (useCases, onComplete) => {
    let serverUrl = getServerUrl();
    let response = await doFetch({
        url: `${serverUrl}/usecases`,
        requestObject: {
            method: "PUT",
            body: JSON.stringify(useCases)
        },
        loadingFn: status => { },
        dataFn: onComplete,
        errorText: "Not found, or put failed."
    })


};



const deleteUseCases = (useCases, onComplete) => {
    let serverUrl = getServerUrl();
    doFetch({
        url: `${serverUrl}/usecases`,
        requestObject: {
            method: "DELETE",
            body: JSON.stringify(useCases)

        },
        loadingFn: status => { },
        dataFn: onComplete,
        errorText: "Not found, or delete failed."
    });
};

export default UseCase;
export { createUseCase, updateUseCases, deleteUseCases, attributes };
