import React, { useContext } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import UseCaseUsesTable from "../tables/UseCaseUsesTable";
import Context from "../mainframe/Context";

const UseCaseUsageModal = props => {
    const { usages, isOpen, toggle } = props;
    let [context] = useContext(Context);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className={context.theme} style={{ "maxWidth": "1400px" }}>
            <ModalHeader toggle={toggle}>Usage by Application</ModalHeader>
            <ModalBody>
                <UseCaseUsesTable uses={usages}></UseCaseUsesTable>
            </ModalBody>
        </Modal>
    );
};

export default UseCaseUsageModal;
