import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import Context from "../mainframe/Context";
import python from "../../util/syntax-highlight/languages/syntax-python";
import vba from "../../util/syntax-highlight/languages/syntax-VBA";
import CodeBlock from "../elements/CodeBlock";

const CodeModal = props => {
    let { useCase, onClose } = props;
    let [context] = useContext(Context);

    let [isOpen, setIsOpen] = useState(true);
    // let [dropDownStates, setDropDownStates] = useState({});

    const toggle = () => {
        if (isOpen) onClose();
        setIsOpen(!isOpen);
    };

    const vbaCodeCD = () => {

        let firstObs = new Date(useCase.firstObsTimestamp)
        let lastObs = new Date(useCase.lastObsTimestamp)

        return (
            <CodeBlock
                code={`    

                
                ' --- UseCase Settings
                Dim dataSettings As New ChilkatJsonObject
                dataSettings.UpdateString "useCase.commodity", "${useCase.commodity}"
                dataSettings.UpdateString "useCase.area", "${useCase.area}"
                dataSettings.UpdateString "useCase.product", "${useCase.product}"
                dataSettings.UpdateString "useCase.source", "${useCase.source}"
                dataSettings.UpdateString "useCase.type", "${useCase.type}"
                dataSettings.UpdateString "useCase.subtype", "${useCase.subtype}"
                dataSettings.UpdateString "useCase.unit", "${useCase.unit}"
                dataSettings.UpdateString "useCase.period", "${useCase.period}"
                dataSettings.UpdateString "useCase.startDate", "${useCase.startDate}"

                ' --- Values Settings
                valuesStartDate = DateSerial(${firstObs.getFullYear()}, ${firstObs.getMonth() + 1}, ${firstObs.getDate()})
                valuesEndDate =  DateSerial(${lastObs.getFullYear()}, ${lastObs.getMonth() + 1}, ${lastObs.getDate()})

                'without time >> returns from start of day
                dataSettings.UpdateString "values.from", Format(valuesStartDate, "YYYY-MM-DD")
                'without time >> returns until end of day (Achtung: kleiner GLEICH endDateTime falls Zeit angegeben!)"
                dataSettings.UpdateString "values.to", Format(valuesEndDate, "YYYY-MM-DD") 

                dataSettings.UpdateString "values.showSource", "true"

                ' --- print request
                dataSettings.EmitCompact = False
                Debug.Print dataSettings.Emit()
                
                Application.Calculation = xlCalculationAutomatic
                
                Dim processSettings As New ChilkatJsonObject
                processSettings.UpdateString "dataSource", "api"
            '    processSettings.UpdateString "keepSourceFileOpen", "true"
                
                Set myData = getOmpContinuousData(dataSettings, processSettings)
                
                myData.EmitCompact = False
                Debug.Print myData.Emit()
                `}
                language={vba}
            ></CodeBlock>
        );
    };

    const vbaCodeNCD = () => {
        return (
            <CodeBlock
                code={`    
                coming soon`}
                language={vba}
            ></CodeBlock>
        );
    };

    const pythonCodeCD = () => {

        let firstObs = new Date(useCase.firstObsTimestamp)
        let lastObs = new Date(useCase.lastObsTimestamp)

        return (
            <CodeBlock
                code={`        dataSettings = chilkat2.JsonObject()
                      
                # --- UseCase Settings
                dataSettings.UpdateString("useCase.commodity", "${useCase.commodity}")
                dataSettings.UpdateString("useCase.area", "${useCase.area}")
                dataSettings.UpdateString("useCase.product", "${useCase.product}")
                dataSettings.UpdateString("useCase.source", "${useCase.source}")
                dataSettings.UpdateString("useCase.type", "${useCase.type}")
                dataSettings.UpdateString("useCase.unit", "${useCase.unit}")
                dataSettings.UpdateString("useCase.period", "${useCase.period}")
                dataSettings.UpdateString("useCase.startDate", "${useCase.startDate}")

                # --- Values Settings
                valuesStartDate = datetime.date(${firstObs.getFullYear()}, ${firstObs.getMonth() + 1}, ${firstObs.getDate()})
                valuesEndDate =  datetime.date(${lastObs.getFullYear()}, ${lastObs.getMonth() + 1}, ${lastObs.getDate()})

                # without time >> returns from start of day
                dataSettings.UpdateString("values.from", f"{valuesStartDate}")  
                # without time >> returns until end of day (Achtung: kleiner GLEICH endDateTime falls Zeit angegeben!)"
                dataSettings.UpdateString("values.to", f"{valuesEndDate}")  

                dataSettings.UpdateString("values.timeZone", "UTC")
                dataSettings.UpdateString("values.showSource", "True")
            
                # --- print request
                dataSettings.EmitCompact = False
                print(dataSettings.Emit())

                processSettings = chilkat2.JsonObject()
                processSettings.UpdateString("dataSource", "api")
            
                myData = getOmpContinuousData(dataSettings, processSettings)
                if myData.StringOf("status") == "success":
                    print(myData.StringOf("status"))
            
                myData.EmitCompact = True
                print(myData.Emit())
       
                    `}
                language={python}
            ></CodeBlock>
        );
    };

    const pythonCodeNCD = () => {
        return (
            <CodeBlock
                code={`    dataArray = None
                dataSettings = chilkat2.JsonObject()
                      
                # --- UseCase Settings
                dataSettings.UpdateString("useCase.commodity", "${useCase.commodity}")
                dataSettings.UpdateString("useCase.area", "${useCase.area}")
                dataSettings.UpdateString("useCase.product", "${useCase.product}")
                dataSettings.UpdateString("useCase.source", "${useCase.source}")
                dataSettings.UpdateString("useCase.type", "${useCase.type}")
                dataSettings.UpdateString("useCase.unit", "${useCase.unit}")
                dataSettings.UpdateString("useCase.period", "${useCase.period}")
                dataSettings.UpdateString("useCase.observation", "${useCase.observation}")

                # --- Values Settings
                dataSettings.UpdateString("values.startDateTime", "${useCase.commodity}")  # without time >> returns from start of day
                dataSettings.UpdateString("values.endDateTime", "${useCase.commodity}")  # without time >> returns until end of day (Achtung: kleiner GLEICH endDateTime falls Zeit angegeben!)"
                dataSettings.UpdateString("values.timeZone", "UTC")
                dataSettings.UpdateString("values.showSource", "True")
                dataSettings.UpdateString("dataArray.type", "timeSeries")  # [matrix|timeSeries|timeSeries24] matrix is default
            
                #print request
                dataSettings.EmitCompact = False
                print(dataSettings.Emit())
            
                myData, dataArray = getOmpNonContinuousData(dataSettings, dataArray)
                if myData.StringOf("status") == "success":
                    print(myData.StringOf("status"))
            
                myData.EmitCompact = True
                print(myData.Emit())
            `}
                language={python}
            ></CodeBlock>
        );
    };

    return (
        <Modal toggle={toggle} isOpen={isOpen} className={context.theme} size="lg">
            <ModalHeader toggle={toggle}>Code UseCase</ModalHeader>
            <ModalBody>
                <UncontrolledAccordion flush defaultOpen="0">
                    <AccordionItem>
                        <AccordionHeader targetId="1">VBA</AccordionHeader>
                        <AccordionBody accordionId="1">{useCase.uc === "ncd" ? vbaCodeNCD() : vbaCodeCD()}</AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">Python</AccordionHeader>
                        <AccordionBody accordionId="2">{useCase.uc === "ncd" ? pythonCodeNCD() : pythonCodeCD()}</AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Cool thx!
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CodeModal;
