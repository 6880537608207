import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import doFetch, { getServerUrl } from "../../util/NetworkUtil";
import { nFormatter } from "../../util/Util";
import Metric from "../elements/Metric";

const MetricsDashboard = props => {
    let [metrics, setMetrics] = React.useState();
    useEffect(() => {
        doFetch({
            url: `${getServerUrl()}/metrics`,
            dataFn: data => {
                setMetrics(data);
            }
        });
    }, []);

    return (
        <>
            {" "}
            <Row>
                <Col sm="4">
                    <h5 className="metric-container">Excel</h5>
                </Col>
                <Col sm="4">
                    <h5 className="metric-container">NCData</h5>
                </Col>
                <Col sm="4">
                    <h5 className="metric-container">CData</h5>
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <div className="metric-container">
                        <Metric title="MB" value={metrics && metrics.excel ? (Math.round((metrics.excel.size) * 100) / 100).toFixed(2) : ""} />
                    </div>
                </Col>
                <Col sm="4">
                    <div className="metric-container">
                        <Metric title="GB" value={metrics && metrics.nonContinuousData ? (Math.round((metrics.nonContinuousData.size / 1024.0) * 100) / 100).toFixed(2) : ""} />
                    </div>
                </Col>
                <Col sm="4">
                    <div className="metric-container">
                        <Metric title="GB" value={metrics && metrics.continuousData ? (Math.round((metrics.continuousData.size / 1024.0) * 100) / 100).toFixed(2) : ""} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <div className="metric-container">
                        <Metric title="UseCases" value={metrics && metrics.excel ? nFormatter(metrics.excel.useCases) : ""} />
                    </div>
                </Col>
                <Col sm="4">
                    <div className="metric-container">
                        <Metric title="UseCases" value={metrics && metrics.nonContinuousData ? nFormatter(metrics.nonContinuousData.useCases) : ""} />
                    </div>
                </Col>
                <Col sm="4">
                    <div className="metric-container">
                        <Metric title="UseCases" value={metrics && metrics.continuousData ? nFormatter(metrics.continuousData.useCases) : ""} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="4"></Col>
                <Col sm="4"></Col>
                <Col sm="4">
                    <div className="metric-container">
                        <Metric title="Values" value={metrics && metrics.continuousData ? nFormatter(metrics.continuousData.values) : ""} />
                    </div>
                </Col>
            </Row>
            <Row>
                {metrics && metrics.replication ? <>
                    <Col sm="1" className={metrics.replication.wsrep_ready === "ON" ? "state-success" : "state-error"} title="wsrep_ready">{metrics.replication.wsrep_ready} </Col>
                    <Col sm="1" className={metrics.replication.wsrep_connected === "ON" ? "state-success" : "state-error"} title="wsrep_connected">{metrics.replication.wsrep_connected} </Col>
                    <Col sm="1" className={metrics.replication.wsrep_local_state_comment === "Synced" ? "state-success" : "state-error"} title="wsrep_local_state_comment" >{metrics.replication.wsrep_local_state_comment ? metrics.replication.wsrep_local_state_comment.toUpperCase() : "DOWN"} </Col>
                    <Col sm="1" > </Col>
                    <Col className="imprint">{metrics ? metrics.host : ""}</Col>


                </> : <></>}

            </Row>
        </>
    );
};

export default MetricsDashboard;
