import React from "react";
import { UncontrolledTooltip } from "reactstrap";


const FinancialIcon = () => <>
    <span id="restriction" className="material-symbols-outlined imprint ms-16 cd-icon">
        attach_money
    </span>
    <UncontrolledTooltip placement="top" target="restriction">
        <b>Financial</b>
        <br></br>Dieses Datenset ist vertraulich
    </UncontrolledTooltip>
</>

export default FinancialIcon;