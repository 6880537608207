import React, { useContext, useRef } from "react";
import Context from "../mainframe/Context";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import { Badge } from "reactstrap";

const UseCaseUsesTable = ({ uses = [], isLoading }) => {
    const [context] = useContext(Context);

    const gridRef = useRef(null);

    const readWriteFormatter = params => {
        if (params.value.includes("get")) return <Badge color="success">GET</Badge>;
        else if (params.value.includes("write")) return <Badge color="warning">WRITE</Badge>;
    };

    const columnDefs = [
        { field: "application", filter: "agTextColumnFilter", cellClass: "omp-cell", resizable: true, flex: 1 },
        { field: "id", filter: "agTextColumnFilter", cellClass: "omp-cell", resizable: true, width: 188 },
        { field: "created", filter: "agTextColumnFilter", cellClass: "omp-cell", resizable: true, width: 140 },
        { field: "function", filter: "agTextColumnFilter", cellClass: "omp-cell", resizable: true, width: 100, cellRenderer: readWriteFormatter },
        { field: "ompModuleVersion", filter: "agTextColumnFilter", cellClass: "omp-cell", resizable: true, width: 140 },
        { field: "param1", filter: "agTextColumnFilter", cellClass: "omp-cell", resizable: true, flex: 1 }
    ];

    if (gridRef.current != null && gridRef.current.api != null)
        if (isLoading) gridRef.current.api.showLoadingOverlay();
        else if (uses.length === 0) gridRef.current.api.showNoRowsOverlay();
        else gridRef.current.api.hideOverlay();

    let rowData = uses;

    //hack to adjust the height

    /**display all useCases in a list */
    return (
        <div className={context.theme === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"} style={{ width: "auto", height: 450 }}>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while we check where this DataSet is used</span>'}
            ></AgGridReact>
        </div>
    );
};

export default UseCaseUsesTable;
