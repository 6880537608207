import _ from "lodash";
import React, { useContext, useState } from "react";
import { FormGroup, Label, InputGroup, Input, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Col, FormFeedback } from "reactstrap";
import { capitalizeFirstLetter } from "../../util/Util";
import Context from "../mainframe/Context";

const InputField = props => {
    let { label, val, options, optionsHeader, updateValue, additionalClasses = "", isValid, disabled = false } = props;

    let [context] = useContext(Context);

    let [dropDownState, setDropDownState] = useState(false);
    // let [valid] = useState(true);

    const validateInput = () => {
        return false;
    };
    console.log(optionsHeader)
    console.log(options)

    let arrOptions;
    if (options === undefined) arrOptions = []
    else arrOptions = Array.from(options)


    return (
        <FormGroup row className={additionalClasses}>
            <Label sm={5} for={label}>
                {capitalizeFirstLetter(label)}
            </Label>
            <Col sm={7}>
                <InputGroup>
                    <ButtonDropdown isOpen={dropDownState} toggle={() => setDropDownState(!dropDownState)} className="input-field-btn-group">
                        <Input
                            id={label}
                            placeholder={"enter " + label}
                            value={val ? val : ""}
                            onChange={e => updateValue(e.target.value)}
                            onBlur={e => (validateInput(e.target.value) ? e.target.setAttribute("valid", true) : e.target.setAttribute("invalid", true))}
                            invalid={isValid === false}
                            bsSize="sm"
                            disabled={disabled}

                        />

                    </ButtonDropdown>
                </InputGroup>
            </Col>
        </FormGroup>
    );
};

export default InputField;
