import React from "react";

const SymbolMessage = ({title ="", icon="", message = "" }) => {
    return (
        <div className="not-found-container">
            <h1>
                <span className="material-symbols-outlined">{icon}</span> {title}
            </h1>

            <p>{message}</p>
        </div>
    );
};

export default SymbolMessage;
