import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import App from "./components/mainframe/App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (window.location.origin.includes("test")) document.body.classList.add('testing');
if (window.location.origin.includes("node")) document.body.classList.add('redundant');
// if (true) document.body.classList.add('testing');
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <App />
    </BrowserRouter>
    // </React.StrictMode>
);


