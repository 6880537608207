import React from "react";
import { Spinner } from "reactstrap";

const Loader = ({ hint = "" }) => (
    <div className="loader" style={{ width: "100%", verticalAlign: "middle" }}>
        <span className="loader-inner" style={{ margin: "auto", display: "table" }}>
            <Spinner className="spinner" color="secondary" />
        </span>
        <span className="loader-inner" style={{ margin: "auto", display: "table" }}>
            {hint}
        </span>
    </div>
);

export default Loader;
