import React from "react";
import { Col } from "reactstrap";
import MetricsDashboard from "../container/MetricsDashboard.js";
import { useNavigate } from "react-router-dom";

const EntryPage = (props) => {
    let { title, subtitle } = props;

    let navigate = useNavigate()
    return (
        <div className="watermark-container flx-container">
            <div className="flx-stretch-row row">
                <Col sm="1" md="1" lg="2" className="flx-stretch-row flx-container"></Col>
                <Col sm="5" md="3" lg="6" className="flx-stretch-row flx-container waterm-container">
                    <div className="omp-watermark">
                        <h1 className="entry-page-title"> {title}</h1>
                        <h4 className="entry-page-sub-title"> {subtitle} </h4>

                        <h4 style={{ display: "flex" }}>

                            <a style={{ marginLeft: "10px" }} className="pointer" onClick={() => navigate("/usecases")} >
                                → zu den Daten
                            </a>
                        </h4>
                    </div>
                </Col>
                <Col sm="3" md="5" lg="3" className="flx-stretch-row flx-container waterm-container">
                    <MetricsDashboard />
                </Col>
                <Col sm="1" md="1" lg="1" className="flx-stretch-row flx-container "></Col>
            </div>
        </div>
    );
};

export default EntryPage;
