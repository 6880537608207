import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col } from "reactstrap";
import AreYouSureModal from "./AreYouSureModal";
import Context from "../mainframe/Context";
import { attributes } from "../elements/UseCase";

const createMaster = useCases => {
    let master = {};
    let lead = useCases[0];
    Object.keys(lead).forEach(key => {
        if (useCases.filter(uc => uc[key] !== undefined && uc[key] === lead[key]).length === useCases.length) master[key] = lead[key];
    });
    return master;
};

const DeleteUseCaseModal = ({ useCases = [], isOpen, setIsOpen, onSubmit }) => {
    let [context] = useContext(Context);

    // let [dropDownStates, setDropDownStates] = useState({});
    let [areYouSureModalState, setAreYouSureModalState] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };


    const useCaseAttributes = {}

    //find all the unique usecase attrs
    useCases.forEach((useCase, i) => attributes.forEach(attribute => {
        if (i === 0) useCaseAttributes[attribute.name] = new Set();
        if (useCase[attribute.name] !== null && useCase[attribute.name] !== undefined)
            useCaseAttributes[attribute.name].add(useCase[attribute.name])
    }))


    const deleteUseCases = useCases => {

        onSubmit(useCases)

        setIsOpen(false)
    }



    return (
        <>
            <Modal toggle={toggle} isOpen={isOpen} className={context.theme} size={"lg"}>
                <ModalHeader toggle={toggle}>{useCases.length} UseCases Löschen</ModalHeader>
                <ModalBody>
                    <Row><p>Die Attrribute der zu löschenden UseCases zur kontrolle:</p></Row>

                    {Object.keys(useCaseAttributes).map(key => { return <Row key={key}><Col xs={3}>{key}:</Col> <Col>{Array.from(useCaseAttributes[key]).join(", ")}</Col></Row> })}

                </ModalBody>
                <ModalFooter>
                    <Button color="" className="danger" onClick={() => setAreYouSureModalState(true)}>
                        {useCases.length} UseCases Löschen
                    </Button>
                    <AreYouSureModal onConfirm={() => deleteUseCases(useCases)} onCancle={() => setIsOpen(false)} isOpen={areYouSureModalState} />

                    <Button color="primary" onClick={toggle}>
                        Abbrechen
                    </Button>

                </ModalFooter>
            </Modal >
        </>
    );
};

export default DeleteUseCaseModal;
