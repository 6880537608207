import React, { useContext, useState } from "react";
import doFetch, { getServerUrl } from "../../util/NetworkUtil";
import ValuesDisplayContainer from "./ValuesDisplayContainer";
import Loader from "../cosmetic/Loader";
import { useEffect } from "react";
import SymbolMessage from "../cosmetic/SymbolMessage";
import Context from "../mainframe/Context";

const UseCaseValuesContainer = ({ useCase = {} }) => {
    /**Hanldes the display of the useCaseValues in a container **/
    let [context] = useContext(Context);

    let [flatUseCaseValues, setFlatUseCaseValues] = useState([]);

    let [loading, setLoading] = useState(true);
    let [error, setError] = useState();

    let [colNames, setColNames] = useState([]);

    let getRowAxisIndex;
    let getColumnAxisIndex;


    let fetchUseCaseValues = () => {
        console.log("rendering");


        if (useCase.uc === "excel") return
        var start = performance.now();

        let queryString = "";
        queryString += `commodity=${useCase.commodity ? encodeURI(useCase.commodity) : ""}`
        queryString += `&area=${useCase.area ? encodeURI(useCase.area) : ""}`
        queryString += `&product=${useCase.product ? encodeURI(useCase.product) : ""}`
        queryString += `&unit=${useCase.unit ? encodeURI(useCase.unit) : ""}`
        queryString += `&observation=${useCase.observation ? encodeURI(useCase.observation) : ""}`
        queryString += `&period=${useCase.period ? encodeURI(useCase.period) : ""}`
        queryString += `&type=${useCase.type ? encodeURI(useCase.type) : ""}`
        queryString += `&subtype=${useCase.subtype ? encodeURI(useCase.subtype) : ""}`
        queryString += `&startDate=${useCase.startDate ? encodeURI(useCase.startDate) : ""}`
        queryString += `&source=${useCase.source ? encodeURI(useCase.source) : ""}`
        queryString += `&dataType=${useCase.ucClassifiaction ? encodeURI(useCase.ucClassifiaction) : ""}`
        queryString += `&restriction=${useCase.restriction ? encodeURI(useCase.restriction) : ""}`


        doFetch({
            url: `${getServerUrl()}/usecases/values?${queryString}`,
            loadingFn: setLoading,
            dataFn: data => {
                if (!data) return
                setFlatUseCaseValues(data);
                console.log(data);
            },
            errorFn: async (response) => {
                let result = await response.json()
                if (response.status === 401) setError(<SymbolMessage title="hidden" message={useCase.restriction + " UseCases are confidential"} icon="visibility_off" />)
            },
            errorText: "Not found, or get failed."
        });
    };

    useEffect(fetchUseCaseValues, [useCase]);

    let refresh = () => {
        fetchUseCaseValues();
    };


    /**
     * Fetches the useCaseData from the server
     */
    if (useCase.uc === "excel") {
        return <SymbolMessage title="excel" message="Values stored in an Excel file cannot be displayed" icon="auto_towing" />;
    } else if (loading) {
        return <Loader hint={useCase.period && useCase.period.includes("sec") ? `Rendering large Data set may take up to 2min` : ""} />;
    } else if (error) {
        return error;
    } else if (flatUseCaseValues.length === 0) {
        return <SymbolMessage title="empty" message="UseCase has no values" icon="data_object" />;
    } else {
        return (
            <ValuesDisplayContainer
                useCase={useCase}
                flatValues={flatUseCaseValues}
                colNames={colNames}
                dateFormatter={getRowAxisIndex}
                refresh={refresh}
            />
        );
    }
};

export default UseCaseValuesContainer;
