/**
 * Sets a browsercookie
 * @param {String} cname cookie name
 * @param {String} cvalue value
 * @param {*} exdays (optional) number of days till it expires
 */
export const setCookie = (cname, cvalue, exdays = 365) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

/**
 * Gets the value of the cookie
 * @param {string} cname
 * @returns value of the cookie or ""
 */
export const getCookie = cname => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

/**
 * Saves  a key/value pair to LocalStorage
 * @param {*} key
 * @param {*} value
 */
export const setToLS = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Gets  a value by key from LocalStorage
 *
 * @param {*} key
 * @returns
 */
export const getFromLS = key => {
    const value = window.localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
};
