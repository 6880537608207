import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const ApiDocContainer = props => {
    return (
        <>
            <SwaggerUI url={props.file} />
        </>
    );
};

export default ApiDocContainer;
