import React, { useLayoutEffect, useState } from "react";
import { getFromLS, setToLS } from "../../util/Storage";

import Context from "./Context";
import MainContainer from "../container/MainContainer";
import Login from "./Login";

/**
 *  App Main Component
 *
 * Includes a Global Context
 * @returns
 */
const App = () => {

    /**Login  */
    let [token, setToken] = useState();

    /**Context may be used to store for example theme info */
    let [context, setContext] = useState();
    let [contextReady, setContextReady] = useState(false);




    /** Context Logic */
    const defaultContext = {
        theme: "light",
        user: {
            locale_string: "de-CH",
            locale: new Intl.Locale("de-CH")
        }
    };

    //init LocalStorage context
    useLayoutEffect(() => {
        let savedContext = getFromLS("context");
        if (!savedContext) {
            savedContext = defaultContext;
        } else {
            savedContext = { ...defaultContext, ...savedContext };
        }
        //transform the stringed locale back
        savedContext.user.locale = new Intl.Locale(savedContext.user.locale_string);
        setContext(savedContext);
        setContextReady(true);
    }, []);

    const doSetContext = context => {
        setContext(context);
        setToLS("context", context);
    };

    /**
     * Login Logic
     */
    // if (!token) return <Login setToken={setToken}></Login>


    if (contextReady)
        return (
            <Context.Provider value={[context, doSetContext]}>
                <div id="app-root" className={context.theme === "dark" ? "dark" : " light"}>
                    {/* <OmpDataContainer /> */}
                    <MainContainer />
                </div>
            </Context.Provider>
        );
};

export default App;
