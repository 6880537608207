import { useState, useEffect } from "react";
// // Usage
// function App() {
//     // Call our hook for each key that we'd like to monitor
//     const happyPress = useKeyPress("h", ()=>{console.log("h")});

/**
 * Keypress event is NEVEr UPDATED.
 * @param {*} targetKey
 * @param {*} callback
 * @returns
 */
function useStaticKeyPress(targetKey, callback) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
        //check if input is in focus
        if (document.activeElement.tagName !== "INPUT") {
            if (key === targetKey) {
                setKeyPressed(true);
                callback();
            }
        }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };
    // Add event listeners
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}

/**
 * Keypress event is updated on every render.
 * @param {*} targetKey
 * @param {*} callback
 * @returns
 */
function useDynamicKeyPress(targetKey, callback) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
        if (key === targetKey) {
            if (document.activeElement.tagName !== "INPUT") {
                setKeyPressed(true);
                callback();
            }
        }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };
    // Add event listeners
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    });
    return keyPressed;
}

export { useStaticKeyPress, useDynamicKeyPress };
