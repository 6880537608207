const vba = {
    keywords: [
        "False",
        "None",
        "True",
        "and",
        "as",
        "assert",
        "break",
        "class",
        "continue",
        "def",
        "del",
        "elif",
        "else",
        "except",
        "finally",
        "for",
        "from",
        "global",
        "if",
        "import",
        "in",
        "is",
        "lambda",
        "nonlocal",
        "not",
        "or",
        "pass",
        "raise",
        "return",
        "try",
        "while",
        "with",
        "yield"
    ],
    functions: [
        "Abs",
        "Array",
        "Asc",
        "AscB",
        "AscW",
        "Atn",
        "CallByName",
        "CBool",
        "CByte",
        "CCur",
        "CDate",
        "CDbl",
        "CDec",
        "Choose",
        "Chr",
        "ChrB",
        "code",
        "ChrW",
        "CInt",
        "CLng",
        "Command",
        "application",
        "Cos",
        "CreateObjectCreates",
        "CSng",
        "CStr",
        "CurDir",
        "CVar",
        "CVErr",
        "Date",
        "DateAdd",
        "DateDiff",
        "DatePart",
        "DateSerial",
        "DateValue",
        "Day",
        "DDB",
        "double-declining",
        "Dir",
        "DoEvents",
        "Environ",
        "EOF",
        "Error",
        "Exp",
        "FileAttr",
        "FileDateTimeReturns",
        "FileLen",
        "Filter",
        "Fix",
        "Format",
        "Format",
        "Currency",
        "Returns",
        "FormatDate",
        "Time",
        "Returns",
        "Format",
        "Number",
        "Returns",
        "Format",
        "Percent",
        "Returns",
        "FreeFile",
        "FV",
        "payments",
        "GetAll",
        "created",
        "registry",
        "GetAttr",
        "GetObject",
        "GetSetting",
        "registry",
        "Hex",
        "Hour",
        "IIf",
        "Input",
        "InputB",
        "InputBox",
        "InStr",
        "InStrB",
        "InStrRev",
        "back",
        "Int",
        "IPmt",
        "periodic,",
        "IRR",
        "IsArray",
        "IsDate",
        "IsEmpty",
        "IsError",
        "IsMissing",
        "IsNull",
        "IsNumeric",
        "IsObject",
        "Join",
        "LBound",
        "LCase",
        "Left",
        "LeftB",
        "Len",
        "LenB",
        "Loc",
        "LOF",
        "Log",
        "LTrim",
        "Mid",
        "MidB",
        "Minute",
        "MIRR",
        "Month",
        "MonthName",
        "MsgBox",
        "Now",
        "NPer",
        "payments",
        "NPV",
        "periodic",
        "Oct",
        "Partition",
        "calculated",
        "Pmt",
        "and",
        "PPmt",
        "on",
        "PV",
        "payments",
        "QBColor",
        "number",
        "Rate",
        "Replace",
        "RGB",
        "SLN",
        "Space",
        "Spc",
        "Split",
        "Sqr",
        "Str",
        "Right",
        "RightB",
        "Rnd",
        "Round",
        "RTrim",
        "Second",
        "Seek",
        "Sgn",
        "Shell",
        "Sin",
        "StrComp",
        "StrConv",
        "String",
        "StrReverse",
        "Switch",
        "first",
        "SYD",
        "period",
        "Tab",
        "Tan",
        "Time",
        "Timer",
        "TimeSerial",
        "TimeValue",
        "Trim",
        "spaces",
        "TypeName",
        "UBound",
        "UCase",
        "Val",
        "VarType",
        "Weekday",
        "Year"
    ],
    commentPrefix: ["'"],
    stringEncloser: ['"']
};

export default vba;
