import React, { useContext, useEffect, useState } from "react";
import doFetch, { getServerUrl } from "../../util/NetworkUtil";

import Header from "../mainframe/Header";
import Footer from "../mainframe/Footer";
import Context from "../mainframe/Context";
import { useStaticKeyPress } from "../../util/KeyEvents";
import DataSetSelector from "./DataSetSelector";
import DetailRoute from "../routes/DetailRoute";
import { UncontrolledTooltip, Row, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Offcanvas, OffcanvasBody, OffcanvasHeader, Toast, ToastHeader, ToastBody, Spinner } from "reactstrap";
import Body from "../mainframe/Body";
import ompSocket from "../websocket/OmpSocket";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UseCaseModal from "../modals/UseCaseModal";
import { createUseCase, deleteUseCases, updateUseCases } from "../elements/UseCase";

/**
 * This is the main, Component it handles most communication to the Backend and does the separation into the three columns
 * CustomerTable, CustomerContainer and the ContentDisplay
 * @param {String} serverUrl The Url of the Backend
 */
const MainContainer = () => {
    const [context, setContext] = useContext(Context);

    let [useCases, setUseCases] = useState([]);
    let [selectionOptions, setSelectionOptions] = useState({
        commodity: new Set(),
        area: new Set(),
        product: new Set(),
        source: new Set(),
        type: new Set(),
        subtype: new Set(),
        unit: new Set(),
        period: new Set(),
        observation: new Set(),
        startDate: new Set(),
        responsiblePerson: new Set(),
        restriction: new Set()
    });
    let [offcanvasState, setOffcanvasState] = useState(false);
    let [dataTypeState, setDataTypeState] = useState(false);
    let [modalUseCases, setModalUseCases] = useState([{}]);
    let [showCreateModal, setShowCreateModal] = useState(false);
    let [showUpdateModal, setShowUpdateModal] = useState(false);
    let [showIndexingToast, setShowShowIndexingToast] = useState(false);
    let [indexingToastMessage, setIndexingToastMessage] = useState("");

    const navigate = useNavigate();

    useStaticKeyPress(" ", () => {
        navigate("/usecases")
    });

    /**
     * Opens a modal to create the useCase
     */
    const openCreateUseCaseModal = () => {
        setModalUseCases([newUseCase]);
        setShowCreateModal(true);
    };



    const doCreateUseCase = useCases => {
        useCases.forEach(useCase =>
            createUseCase(useCase, result => {
                console.log(result);
            })
        );
    };

    const doUpdateUseCase = useCases => {
        updateUseCases(useCases, () => { })
    };

    const doDeleteUseCase = useCases => {
        useCases.forEach(useCase => deleteUseCases(useCase, () => { }));
    };

    //init new usecase that is empty
    const newUseCase = {
        commodity: undefined,
        area: undefined,
        product: undefined,
        source: undefined,
        type: undefined,
        subtype: undefined,
        unit: undefined,
        period: undefined,
        restriction: "general",
        uc: "ncd"
    };

    const toggleTheme = () => {
        setContext({ ...context, theme: context.theme === "dark" ? "light" : "dark" });
    };

    const toggleToast = () => {
        setShowShowIndexingToast(!showIndexingToast);
    };

    const refreshIndex = () => {
        doFetch({
            url: `${getServerUrl()}/index/ncd`,
            requestObject: { method: "PUT" },
            loadingFn: state => {
                if (state) {
                    setIndexingToastMessage("NCD werden indexiert... Dies kann einige Zeit dauern");
                    setShowShowIndexingToast(true);
                } else {
                    setTimeout(() => setShowShowIndexingToast(false), 4000);
                }
            },
            dataFn: response => {
                setIndexingToastMessage(response.count + " Einträge wurden aktualisiert ");

                // setLoadingUsages(false);
                // setLogUsages(response);
                //adjust the id incase this was a csvusecase
            },
            errorText: "Not found, or put failed."
        });
    };
    const consoleError = console.error;
    const SUPPRESSED_ERRORS = [
        "******************************",
        "If you want to hide the watermark,",
        "This is an evaluation only version,",
        "All AG Grid Enterprise",
        "info@ag-grid.com",
        "defaultProps will be removed from function components"
    ];

    console.error = function filterErrors(msg, ...args) {
        if (!SUPPRESSED_ERRORS.some(entry => msg.includes(entry))) {
            consoleError(msg, ...args);
        }
    };

    // const generateUseCaseModal = () => {
    //     if (showCreateModal)
    //         return (
    //             <UseCaseModal
    //                 useCases={modalUseCases}
    //                 selectionOptions={selectionOptions}
    //                 onSubmit={doCreateUseCase}
    //                 onClose={() => setShowCreateModal(false)}
    //             />
    //         );
    //     else if (showUpdateModal)
    //         return (
    //             <UseCaseModal
    //                 useCases={modalUseCases}
    //                 selectionOptions={selectionOptions}
    //                 onSubmit={doUpdateUseCase}
    //                 onDelete={doDeleteUseCase}
    //                 onClose={() => setShowUpdateModal(false)}
    //             />
    //         );
    // };

    /**
     * This return statement corresponds to the .render() mehtod in react. Since reactHooks were used this statement looks a little different
     */
    return (
        <div className="flx-container content">
            <Header
                rightContent={
                    <>
                        <span className="link-container">
                            <Link to="/api-doc">API</Link>
                        </span>
                        <span id="theme-toggle" className="material-symbols-outlined pointer" href="#" onClick={toggleTheme}>
                            {context.theme === "dark" ? "light_mode" : "dark_mode"}
                        </span>
                        <UncontrolledTooltip placement="bottom" target="theme-toggle">
                            {context.theme === "dark" ? "Light mode" : "Dark mode"}
                        </UncontrolledTooltip>
                        <span id="refresh-icon" className="material-symbols-outlined pointer" href="#" onClick={refreshIndex}>
                            autorenew
                        </span>
                        <UncontrolledTooltip placement="bottom" target="refresh-icon">
                            NCD Index aktualisieren
                        </UncontrolledTooltip>
                        <span id="help-icon" className="material-symbols-outlined" href="#">
                            help
                        </span>
                        <UncontrolledTooltip placement="bottom" target="help-icon">
                            <div>
                                Bei Fragen Digital Solutions oder den Analyse Desk kontaktieren

                            </div>
                        </UncontrolledTooltip>

                        <Toast isOpen={showIndexingToast}>
                            <ToastHeader
                                icon={
                                    indexingToastMessage.includes("NCD") ? (
                                        <Spinner size="sm">Loading...</Spinner>
                                    ) : (
                                        <span className="material-symbols-outlined">check</span>
                                    )
                                }
                                toggle={toggleToast}
                            >
                                Indexing NCD
                            </ToastHeader>
                            <ToastBody>{indexingToastMessage}</ToastBody>
                        </Toast>
                    </>
                }
            />

            <Body
                content={
                    <>

                        <Row className="flx-stretch-row">
                            {" "}
                            <DetailRoute useCases={useCases} selectionOptions={selectionOptions} />
                        </Row>
                    </>
                }
            />
            <Footer message="@Ompex Digital Solutions" />
        </div>
    );
};

export default MainContainer;
