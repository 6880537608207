import React, { useState, useEffect } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";




/**
 * 
 * Usage:
 * 
 * 
 * 
 *    const [notification, setNotifications] = useState([]);
 * 
 *    const notificationManager = getNotificationManager(notification, setNotifications)
 *
 *    const showNotificationExample() => {
 *        let notification = notificationManager.createNotification("Kopiert", <span className="material-symbols-outlined">check</span>, JSON.stringify(filter, null, 2))
 *        setTimeout(() => notificationManager.dismiss(notification), 4000)
 *    }
 *
 *    return (<>
 *        <NotificationCenter
 *            notificationManager={notificationManager}
 *        </NotificationCenter>
 *       </>)
 *
 * Pass NotificationManager to childComponents if they need to display Notifications
 *    
 * 
 * 
 * @param {*} param0 
 * @returns 
 */
const NotificationCenter = ({ notificationManager }) => {

    // // useEffect(() => {
    //     setNotifications()



    // }, [])

    /**Handles the display of the Notifications **/
    return (
        <>
            <div className="notification-area">
                {notificationManager.notifications.map((notification, i) => (<Toast key={"notification-" + i}

                    isOpen={true}
                    fade={true} >
                    <ToastHeader
                        icon={notification.icon}
                        toggle={() => notificationManager.dismiss(notification)}>
                        {notification.title}
                    </ToastHeader>
                    <ToastBody style={{ whiteSpace: "pre-wrap" }}>
                        {notification.message}
                    </ToastBody>
                </Toast >))}

            </div>

        </>
    );
};


/**
 * Use useNotificationManager to get a notificationManager
 */
class NotificationManager {

    constructor(notifications, setNotifications) {
        this.notifications = notifications;
        this.setNotifications = setNotifications;
    }

    // notifications = []

    dismiss(notification) {
        //refresh the reference

        this.notifications.pop(notification);
        this.setNotifications([...this.notifications]);
    }

    createNotification(title = "Notification", icon, message = "Test Messager") {

        //convert to obj
        let notification = {
            title: title,
            icon: icon,
            message: message
        }

        //refresh the reference
        this.setNotifications([...this.notifications, notification]);

        return notification;
    }

    /**
     * Use this to get all the 
     * @returns 
     */
    getNotifications() {
        return this.state.notifications;
    }
};

/**
 * Use to get a Notification Manager
 * @returns 
 */
const getNotificationManager = (notifications, setNotifications) => {

    return new NotificationManager(notifications, setNotifications)
}

export default NotificationCenter;
export { getNotificationManager };
