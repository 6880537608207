import React from "react";

Array.prototype.includes = function(value) {
    return this.indexOf(value) !== -1;
};
String.prototype.characterize = function(callback) {
    var characters = this.split("");

    for (var i = 0; i < this.length; i++) {
        callback(characters[i]);
    }
};

// window.addEventListener("load", function() {
// $textarea = document.getElementById("textarea-input");
// $highlight = document.getElementById("highlight-area");

const generateHighlightedSyntax = (string, language) => {
    var tokens = tokenize(string, language);
    let highlight = [];
    // $highlight.innerHTML = "";
    for (var i = 0; i < tokens.length; i++) {
        var token = tokens[i];
        var tag;
        if (token.type === "newline") tag = <br />;
        else tag = <span className={"highlight-" + token.type}>{token.value}</span>;
        // document.createElement("span");
        // span.className = "highlight-" + token.type;
        // span.innerText = token.value;
        highlight.push(tag);
    }

    return highlight;
    // highlight.scrollTop = $textarea.scrollTop;
};

// var triggerHighlight = function() {
//     var tokens = tokenize($textarea.value);
//     $highlight.innerHTML = "";
//     for (var i = 0; i < tokens.length; i++) {
//         var token = tokens[i];
//         var span = document.createElement("span");
//         span.className = "highlight-" + token.type;
//         span.innerText = token.value;
//         $highlight.appendChild(span);
//     }
//     var lines = $textarea.value.split("\n");
//     if (lines[lines.length - 1] === "") {
//         var br = document.createElement("br");
//         $highlight.appendChild(br);
//     }
//     $highlight.scrollTop = $textarea.scrollTop;
// };

// $textarea.addEventListener("input", triggerHighlight);
// $textarea.addEventListener("scroll", function(event) {
//     $highlight.scrollTop = this.scrollTop;
// });

// var tabCode = 9;
// var leftParenthesisCode = 40;
// $textarea.addEventListener("keydown", function(event) {
//     switch (event.keyCode) {
//         case tabCode:
//             event.preventDefault();
//             this.value += "    ";
//             break;
//     }
// });

// $textarea.textContent = code;
// $highlight.textContent = code;
// triggerHighlight();
// });

function tokenize(inputString, language) {
    var tokens = [];
    var lexedValue = "";
    var currentToken = null;

    function newSpaceToken() {
        currentToken = { type: "space", value: " " };
        lexedValue = "";
    }

    function parseLexedValueToToken() {
        if (lexedValue) {
            if (language.keywords.includes(lexedValue)) {
                tokens.push({ type: "keyword", value: lexedValue });
            } else if (language.functions.includes(lexedValue)) {
                tokens.push({ type: "function", value: lexedValue });
            } else if (lexedValue !== "") {
                if (isNaN(lexedValue)) {
                    tokens.push({ type: "default", value: lexedValue });
                } else {
                    tokens.push({ type: "number", value: lexedValue });
                }
            }
            lexedValue = "";
        }
    }

    function lex(char) {
        if (char !== " " && currentToken && currentToken.type === "space") {
            tokens.push(currentToken);
            lexedValue = "";
            currentToken = null;
        }

        switch (char) {
            case " ":
                if (language.keywords.includes(lexedValue)) {
                    tokens.push({ type: "keyword", value: lexedValue });
                    newSpaceToken();
                } else if (language.functions.includes(lexedValue)) {
                    tokens.push({ type: "function", value: lexedValue });
                    newSpaceToken();
                } else if (lexedValue !== "") {
                    if (isNaN(lexedValue)) {
                        tokens.push({ type: "default", value: lexedValue });
                    } else {
                        tokens.push({ type: "number", value: lexedValue });
                    }
                    newSpaceToken();
                } else if (currentToken) {
                    currentToken.value += " ";
                } else {
                    newSpaceToken();
                }
                break;

            case "=":
            case "+":
            case "-":
            case "*":
            case "/":
            case "%":
            case "&":
            case "|":
            case ">":
            case "<":
            case "!":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "operator", value: char });
                }
                break;

            case ":":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "colon", value: char });
                }
                break;

            case "(":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "left-parentheses", value: char });
                }
                break;

            case ")":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "right-parentheses", value: char });
                }
                break;

            case "[":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "left-bracket", value: char });
                }
                break;

            case "]":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "right-bracket", value: char });
                }
                break;

            case ",":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "comma", value: char });
                }
                break;

            case "\n":
                if (currentToken) {
                    switch (currentToken.type) {
                        case "string":
                        case "comment":
                            tokens.push(currentToken);
                            currentToken = null;
                            break;
                        default:
                    }
                } else {
                    parseLexedValueToToken();
                    lexedValue = "";
                }
                tokens.push({ type: "newline", value: "\n" });
                break;

            case ";":
                if (currentToken) {
                    currentToken.value += char;
                } else {
                    parseLexedValueToToken();
                    tokens.push({ type: "semicolon", value: char });
                }
                break;

            default:
                //handle comments
                if (language.commentPrefix.includes(char)) {
                    if (currentToken) {
                        currentToken.value += char;
                    } else {
                        parseLexedValueToToken();
                        currentToken = { type: "comment", value: char };
                    }
                } else if (language.stringEncloser.includes(char)) {
                    if (currentToken) {
                        if (currentToken.type === "string") {
                            if (currentToken.value[0] === char) {
                                currentToken.value += char;
                                tokens.push(currentToken);
                                currentToken = null;
                            } else {
                                currentToken.value += char;
                            }
                        } else if (currentToken.type === "comment") {
                            currentToken.value += char;
                        }
                    } else {
                        if (lexedValue) {
                            tokens.push({ type: "default", value: lexedValue });
                            lexedValue = "";
                        }
                        currentToken = { type: "string", value: char };
                    }
                } else if (currentToken) {
                    currentToken.value += char;
                } else {
                    lexedValue += char;
                }

                break;
        }
    }

    /* Lexing the input codes */
    inputString.characterize(lex);

    /* Rest of the lexed value or token which is unfinished */
    parseLexedValueToToken();

    if (currentToken) tokens.push(currentToken);

    /* Secondary Parse to Match Some Patterns */
    var isFunctionArgumentScope = false;
    var tokenCount = tokens.length;
    for (var i = 0; i < tokenCount; i++) {
        var token = tokens[i];
        if (token.type === "keyword" && (token.value === "def" || token.value === "class")) {
            let peekToken = tokens[i + 2];
            if (peekToken && peekToken.type === "default") peekToken.type = "function-name";
        } else if (token.type === "default" && isFunctionArgumentScope) {
            token.type = "argument";
        } else if (token.type === "left-parentheses") {
            let peekToken = tokens[i - 1];
            if (peekToken && peekToken.type === "function-name") isFunctionArgumentScope = true;
        } else if (token.type === "right-parentheses") {
            isFunctionArgumentScope = false;
        }
    }

    return tokens;
}

export default generateHighlightedSyntax;
