import React from "react";

// Ohne Destructuring.
const Body = (props) => (
    <div className="flx-stretch-row flx-container body">
        <div className="flx-stretch-row flx-container ">{props.content}</div>
    </div>
);

export default Body;
