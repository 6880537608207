import React, { useLayoutEffect, useContext } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import Context from "../mainframe/Context";

const ValueChart = ({ data = [], useCase }) => {
    const [context] = useContext(Context);
    useLayoutEffect(() => {
        data.forEach(dp => {
            dp.date = new Date(dp.date).getTime();
        });

        let root = am5.Root.new("chartdiv");
        if (context.theme === "dark") root.setThemes([am5themes_Animated.new(root), am5themes_Dark.new(root)]);

        // root.setThemes([am5themes_Animated.new(root)]);

        //chart creation
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout,
                maxTooltipDistance: 1000
            })
        );

        //cursor
        var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "zoomX"
            })
        );
        cursor.lineY.set("visible", false);

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "minute", count: 1 },
                // groupData: true,
                // renderer: am5xy.AxisRendererX.new(root, {}),
                renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 50
                }),
                tooltip: am5.Tooltip.new(root, {}),
                categoryField: "date"
            })
        );

        yAxis.children.moveValue(am5.Label.new(root, { text: useCase.unit, rotation: -90, y: am5.p50, centerX: am5.p50 }), 0);
        xAxis.data.setAll(data);

        //mutate the data
        if (data.length > 0) {
            Object.keys(data[0]).forEach(key => {
                if (key !== "id" && key !== "useCase_id" && key !== "observationDate" && key !== "date" && key !== "locked") {
                    let series = chart.series.push(
                        am5xy.LineSeries.new(root, {
                            name: key,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: key,
                            valueXField: "date",
                            minBulletDistance: 100,
                            tooltip: am5.Tooltip.new(root, {
                                pointerOrientation: "horizontal",
                                labelText: "[bold]{name}[/]: {valueY} " + useCase.unit
                            }),
                            focusable: true,
                            locationX: 0.5
                        })
                    );
                    //make snap
                    chart.set(
                        "cursor",
                        am5xy.XYCursor.new(root, {
                            snapToSeries: [series],
                            snapToSeriesBy: "x"
                        })
                    );
                    // series.get("tooltip").label.set("text", );
                    series.data.setAll(data);
                }
            });
        }

        // Add legend
        let legend = chart.children.push(am5.Legend.new(root, {}));
        legend.data.setAll(chart.series.values);

        // Add cursor
        // chart.set("cursor", am5xy.XYCursor.new(root, {}));

        //scrollbar
        chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

        return () => {
            root.dispose();
        };
    }, [context]);

    return <div id="chartdiv" style={{ width: "100%", height: "101%", marginTop: "-1%" }}></div>;
};
export default ValueChart;
